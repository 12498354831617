



import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
// import Schedule from "../components/schedule"
import MiniLayout from "../../components/minimalLayout"


const ReviewMini2 = ({data}) => {

  return (
    <Layout>
      <MiniLayout>

        <h2>review Mini Project #2</h2>
        <p>each project should receive a minimum of 3 feedbacks.</p>
        <p>a feedback contains 3 parts: (1) something you think is great about the project, (2) something you think needs improvement and (3) a suggestion of what this improvement could be. </p>

        <br></br>
        <p>here is how we achive this:</p>
        <p>1. join the Slack channel called "...-mini-project-2-review" (it's private, I'll invite you)</p>
        <p>2. find a post that (a) doesn't show your own project and (b) has less than <b>four</b> 🖐 reactions.</p>
        <p>3. before you review the project, immediately react 🖐 to claim it.</p>
        <p>4. click the link provided (the name above the image in the post links to the project), give your feedback in the form of a comment to the post in Slack.</p>
      </MiniLayout>
    </Layout>
  )
}



export default ReviewMini2
